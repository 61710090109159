@import '../../atoms/scss/global';

.advance-control {
  background-color: #fafafa;
  padding: 20px 10px 0 10px;
  .second-buttons-col {
    z-index: 1000;
    max-width: max-content !important;

    .fa-filter {
      position: relative;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
      &::after {
        content: 'x';
        position: absolute;
        top: -1px;
        right: -2px;
        font-size: 13px;
        font-weight: bold;
        font-family: sans-serif;
        color: red;
      }

      &.disabled {
        pointer-events: none;
        color: gray;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  .view-options {
    margin-left: 0 !important;
  }
  h4 {
    text-align: center;
    margin: 10px 0 30px !important;
  }

  .first-buttons-col {
    padding: 0 15px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .select-date-container {
      position: relative;

      .legend {
        position: absolute;
        top: 32 px;
      }
    }
  }
  .search-inputs {
    input,
    .ant-select {
      width: 150px;
    }
  }
  .top-select {
    margin-right: 10px;
    .ant-select {
      min-width: 250px;
    }

    &.long {
      .ant-select {
        min-width: 220px;
      }
    }
  }

  .date-select {
    .ant-select {
      min-width: 120px;
    }
  }

  .cap-level {
    color: $green;
    font-weight: bold;
  }

  .spin {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 30px;
  }
}
.id-filter {
  width: 100px;
}
.name-filter {
  width: 200px;
}

.edit-button {
  padding: 4px 8px !important;
  max-height: 26px;
}
