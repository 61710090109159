@import '../../atoms/scss/global';

.management {
  margin: auto;
  background-color: #fafafa;
  padding: 20px 10px 0 10px;

  .container-menu {
    max-height: 55vh;
    width: 65vw;
    margin-left: 10vw;
  }

  .first-buttons-col {
    padding: 0 15px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .top-select {
    .ant-select {
      min-width: 250px;
    }

    &.long {
      .ant-select {
        min-width: 220px;
      }
    }
  }
  .menu-center {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 62vh;
    margin: auto;

    .ant-menu-title-content {
      color: #363131 !important;
    }

    .ant-menu-item-selected {
      background-color: #fddeb6 !important;
    }
  }

  .ant-menu-item {
    border-bottom: 1px solid $orange !important;
  }

  .containertable {
    width: 72%;
    margin: auto;
  }

  .container-options {
    height: 62vh;
    width: 15vw;
    background: rgb(245, 244, 244);
  }

  .color-data {
    color: rgb(253, 0, 0);
  }

  .color-data-ok {
    color: rgb(0, 0, 0);
    padding: 0.5rem;
  }

  .color-icon-menu {
    color: $orange !important;
  }

  .iconfonts {
    width: 17px;
    color: $orange;
    margin-right: 5px;
    margin-bottom: 4px;
    filter: invert(72%) sepia(51%) saturate(2733%) hue-rotate(349deg) brightness(93%) contrast(104%);
  }
}

.kendo-table-management.k-treelist-scrollable {
  /* width */
  height: 55vh;
  margin-top: 6px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;

    &:hover {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e5e5ea;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #575757;
    background: rgba(45, 45, 45, 0.5);
    opacity: 0.5;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
  * {
    font-size: 12px;
    font-style: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  table {
    tr:hover .k-grid-header-sticky,
    tr:hover .k-grid-footer-sticky,
    tr.k-state-hover .k-grid-header-sticky,
    tr.k-state-hover .k-grid-footer-sticky {
      background: #fff !important;
    }

    th {
      padding: 4px 8px !important;
    }
    .k-grid-header .k-sorted,
    .k-grid-header .k-link:hover {
      color: #fff !important;
    }
    thead.k-grid-header {
      tr {
        &:hover {
          th {
            background-color: $orange !important;
          }
        }
      }
      background-color: $orange;
      th.k-header {
        z-index: 996;
        text-align: center;
        max-height: 37.5px;
        vertical-align: middle;
        color: #fff;
        .k-link {
          cursor: default;
          margin: 0;
          padding: 0 24px;
          padding-left: 0;
          padding-right: 0;
        }
        .k-column-title {
          margin: auto;
        }
        .k-cell-inner {
          max-width: 100%;
          margin: 0;
        }
        border-color: $darken-gray !important;

        &:hover {
          background-color: $primary-color;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #b9ddfe;
          td {
            background-color: #b9ddfe !important;
          }
        }

        .k-master-row:hover .k-grid-content-sticky,
        .k-master-row:hover .k-grid-row-sticky,
        .k-master-row.k-state-hover .k-grid-content-sticky,
        .k-master-row.k-state-hover .k-grid-row-sticky {
          background-color: #b9ddfe !important;
        }

        td.selected {
          background-color: $primary-color !important;
          color: #fff;
        }
        td {
          padding: 0px 8px !important;
          border-left: 1px solid;
          border-color: #f2f2f5 !important;

          .fa-search {
            cursor: pointer;
          }

          .card-button {
            max-height: 26px;
          }

          &.k-border-right {
            border-right: 1px solid $darken-gray !important;
          }

          &.date-picker-editable-cell {
            padding: 0 !important;
            .ant-picker {
              padding-left: 4px;
              padding-right: 4px;
            }
          }
          .name-ins {
            display: -webkit-box;
            max-width: max-content;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          &.gray-cell {
            background-color: $light-gray;
          }

          &.blue-cell {
            background-color: #b9ddfe;
          }

          &.orange-cell {
            background-color: $light-orange;
          }

          &.k-grid-row-sticky {
            top: 90px;
            z-index: 996;
          }

          &.select-cell {
            padding: 0 !important;
            .ant-select {
              background-color: $light-yellow;
              width: 100%;
              max-height: 28px;
              .ant-select-selector {
                border: none;
              }

              & > * {
                background-color: $light-yellow;
              }
            }
          }
        }
      }
    }

    .k-icon {
      margin-right: 10px;
      &:before {
        font-size: 16px !important;
      }
      &.k-i-expand {
        transform: rotate(90deg) !important;
      }
      &.k-i-collapse {
        transform: rotate(-180deg) !important;
      }
    }
  }

  &.loading {
    opacity: 0.4;
    z-index: 1;
    &:after {
      content: 'Cargando...';
      font-size: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 2;
      color: #000;
      font-size: 12px;
      font-weight: 600;
    }

    .k-grid-norecords {
      opacity: 0;
    }
  }
}
.background-cell {
  text-align: center !important;
}

.ok-color {
  color: rgb(0, 0, 0);
  text-align: center !important;
}

.red-color {
  color: rgb(245, 76, 76);
  text-align: center !important;
}

.check-color {
  color: rgb(73, 253, 1);
  font-size: 20px !important;
  text-shadow: 0 0 5px rgb(253, 253, 253), 1px 1px 0 #3b1313;
}

.button-url {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 4px;

  input {
    color: rgb(0, 0, 0);
    background: #fff;
    border-radius: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  input:hover {
    color: rgb(2, 2, 2);
    font-weight: bold;
  }
}

@keyframes alert {
  from {
    transform: none;
  }
  50% {
    transform: scale(1.4);
  }
  to {
    transform: none;
  }
}

.warning {
  display: inline-block;
  font-size: 14px;
  text-shadow: 0 0 10px rgb(253, 253, 253), 1px 1px 0 #3b1313;
  color: red;
  animation: alert 1.5s infinite;
  transform-origin: center;
  margin-left: 4px;
}
