@import '../../atoms/scss/global';

.activities-filter {
  position: relative;
  z-index: 3;
  border-radius: 4px;

  .options-container {
    position: absolute;
    left: 0;
    border: 1px solid $dark-gray;
    background: #fff;
    min-width: 340px;
    border-radius: 4px;

    .toggle-button {
      margin: 0 8px 0 0;
    }

    .option-text {
      margin-left: 8px;
    }

    &.not-popup {
      position: initial;
      border: none;
    }

    .options-header {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dark-gray;

      span {
        font-weight: bold;
        font-size: 18px;
      }

      i.fa {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .search-wrapper {
      padding: 15px 8px;
    }

    .with-children {
      & > label {
        background: $dark-gray;
        border: 1px solid $dark-gray;
      }
    }

    .toggle-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      cursor: pointer;
      background: $dark-gray;
      border: 1px solid $dark-gray;

      span {
        font-size: 14px;
        font-weight: bold;
      }

      i.fa {
        font-size: 16px;
      }
    }

    .options {
      min-height: 150px;
      padding-bottom: 10px;
      max-height: 250px;
      overflow-y: auto;

      &.scrollable {
        max-height: 300px;
        overflow-y: auto;
      }

      .checkbox-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-weight: bold;

        .ant-checkbox-wrapper {
          margin-right: 8px;
        }

        .node-element {
          display: flex;
          align-items: center;
          padding-top: 7px;
        }

        .head-toggle {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 4px 8px 4px 0;
          background-color: #dddddd;
          border-bottom: 1px solid $dark-gray;

          .ant-checkbox-wrapper {
            margin: 0;
          }

          i.fa {
            cursor: pointer;
            font-size: 16px;
          }
        }

        &.level-1 {
          padding-left: 0;
          & > .head-toggle,
          & > div:not(.head-toggle) > label {
            padding-left: 10px;
          }
        }

        &.level-2 {
          & > .head-toggle,
          & > div:not(.head-toggle) > label {
            padding-left: 20px;
          }
        }

        &.level-3 {
          & > .head-toggle,
          & > div:not(.head-toggle) > label {
            padding-left: 30px;
          }
        }
      }

      .view-more {
        display: flex;
        justify-content: flex-end;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 8px;

      button {
        width: 48%;
        margin: 0;
      }
    }
  }
}
