.attendanceTable {
  color: #000;
  text-transform: capitalize;

  .rs-table-cell-content {
    font-size: 14px !important;
    padding: 0px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;

    > i {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .cloneSupervisor:hover {
    color: #1890ff !important;
  }

  .inputContainer {
    display: flex;
    justify-content: center;

    .input {
      color: #000 !important;
      max-width: 30px;
      font-size: 12px;
      padding-left: 0px;
      padding-right: 0px;
      text-align: center;
    }
  }

  .headerColor {
    background-color: #eaeaea !important;
  }

  .legendsContainer {
    margin-top: 20px;
    display: flex;
    border: 1px solid #eee;
    justify-content: space-around;
    padding: 10px 0px;
    flex-wrap: wrap;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    .legendContainer {
      display: flex;
      align-items: center;

      & div {
        color: rgb(0, 0, 0);
        border-radius: 4px;
        margin-right: 5px;
        min-width: 30px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
  }

  .cellWithAttendance {
    // color: rgb(0, 0, 0) !important;
    min-height: 20px;
    min-width: 20px;
    max-width: 30px;
    max-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    margin-left: 5px;
  }

  .ant-tooltip-inner {
    color: black !important;
    background-color: white !important;
  }

  .ant-select-selector {
    background-color: #fadcab !important;
  }
  .ant-tooltip-placement-bottom {
    border-bottom-color: white !important;
  }
  .worker-without-supervisor {
    background-color: #fadcab !important ;
    text-align: center;
  }

  .worker-with-supervisor {
    background-color: white !important ;
  }

  .paddingRowPopover {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
  }

  .common-table {
    .k-grid-header .k-header {
      height: 10px;
      text-align: center;
      background: #007bff !important;
      color: #fff;
    }

    .k-column-resizer {
      background: #fff !important;
      width: 1px !important;
    }
    .k-column-resizer:hover {
      background: #fff !important;
      width: 5px !important;
    }

    .k-cell-inner > .k-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 0px !important;
    }

    // .k-grid-header .k-header > .k-link {
    //     // margin: -16px -24px;
    //     padding: 0px 0px;
    //     line-height: inherit;
    //     display: block;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     outline: 0;
    //     // padding-right: 20px;

    // }

    // td {
    //   border: none;
    //   border-top: 0.1px solid rgba(238, 236, 236, 0.644);
    //   border-right: 0px solid rgb(182, 182, 182);
    //   // padding: 0px;
    //   // text-align: left;

    //   // margin-left: 20px;

    // }
  }

  .ant-spin-dot.ant-spin-dot-spin i {
    width: 20px !important;
    height: 20px !important;
  }
}

.ant-popover-inner-content {
  width: 25rem !important;
}

.dayToClosed-modal {
  .buttons-selection {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
      margin: 0;
    }
  }
}

.ant-popover-title {
  text-align: center !important;
  margin: auto;
  background-color: rgb(123, 130, 138) !important;
  color: white !important;
}

.typesAttendancePopover {
  border-radius: 50%;
  color: white !important;
  font-size: 13.8px;
  text-align: center;
  width: 2.3rem;
  margin-bottom: 0.5rem;
}

.spin {
  position: adsolute;
  top: 0;
  left: 0;
}
