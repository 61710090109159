@import '../../../../atoms/scss/global';

.add-payment-state {
  width: max-content;
}

.currency {
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: -10px;
  font-weight: bold;
}

.add-payment-state-modal {
  .ant-input,
  .ant-form-item-control-input-content {
    background-color: $light-yellow !important;
  }
}
