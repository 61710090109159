@import '../../atoms/scss/global';

.switch-filter {
  position: relative;
  z-index: 3;
  border-radius: 4px;

  .options-container {
    position: absolute;
    left: 0;
    border: 1px solid $dark-gray;
    background: #fff;
    min-width: 340px;
    border-radius: 4px;

    .options-header {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dark-gray;

      span {
        font-weight: bold;
        font-size: 18px;
      }

      i.fa {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .options-content {
      display: flex;
      flex-direction: column;
      padding: 8px;

      :last-child {
        padding-bottom: 0px !important;
      }

      .option {
        display: flex;
        padding-bottom: 8px;

        .optionName {
          padding-left: 10px;
        }

        .ant-switch-checked {
          background-color: $light-green;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 0px 8px 8px 8px;

      button {
        width: 48%;
        margin: 0;
      }
    }
  }
}
