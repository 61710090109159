@import '../../../../atoms/scss/global';

.create-item-editable-cell {
  .rs-table-cell {
    background: $light-yellow !important;
  }
  input {
    height: 20px;
    font-size: 12px;
  }
}
