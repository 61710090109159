@import '../../../atoms/scss/global';

.modalContainer {
  .ant-modal {
    top: 10px;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    button {
      min-width: 150px;
    }
  }

  .ant-picker {
    width: 100% !important;
  }

  .inputForm {
    width: 100%;
  }

  .dates {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
      width: 45% !important;
    }
  }
  .ant-select-selector {
    background-color: white !important;
  }
}
