@import '../../atoms/scss/global';
.users {
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 5px 18px 15px -19px #646161;

  i.fa.fa-edit {
    cursor: pointer;
    font-size: 22px;
  }

  .rs-table-cell-header .rs-table-cell-content {
    background: $light-gray !important;
  }
  .rs-table-cell-content {
    color: black;
  }

  .rs-table-cell-header-icon-sort::after {
    color: #fff;
  }
}
