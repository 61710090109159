@import '../atoms/scss/global';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.ant-btn:not(.ant-btn-primary) {
  &:hover,
  &:focus {
    color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

input {
  &:focus,
  &:hover {
    border-color: $primary-color !important;
  }
}

.ant-form-item-required::before {
  display: none !important;
}

.ant-steps {
  margin-bottom: 20px !important;

  .ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: $primary-color !important;
    background-color: transparent;
    .ant-steps-icon {
      color: $primary-color !important;
      svg {
        position: relative;
        bottom: 3px;
      }
    }
  }

  .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: $primary-color !important;

    .ant-steps-icon {
      color: #fff !important;
    }
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: $primary-color !important;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #000 !important;
  }
}

.ant-spin-dot.ant-spin-dot-spin {
  i {
    background-color: $primary-color !important;
  }
}

.ant-radio {
  outline: none;
  &:hover {
    .ant-radio-inner {
      border-color: $primary-color !important;
    }
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: $primary-color;
      &::after {
        background-color: $primary-color;
      }
    }
  }

  .ant-radio-inner {
    &:hover {
      border-color: $primary-color !important;
    }
  }
}

.ant-table-thead > tr > th,
.header-table-color {
  background: #eaeaea !important;
  font-weight: bold;
}

.ant-form {
  .ant-form-item {
    padding-bottom: 5px;
    position: relative;

    .ant-form-item-label {
      position: absolute;
      top: -12px;
      z-index: 2;
      left: 10px;
      padding: 0px 4px;

      &:before {
        content: '';
        width: 100%;
        position: absolute;
        background: #fff;
        height: 3px;
        top: 10px;
        right: 1px;
      }

      & > label {
        font-size: 12px;
        font-weight: normal;
        height: 12px;
      }
    }
  }
}
