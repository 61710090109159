.input-with-unit {
  &::before {
    content: '$';
    position: absolute;
    top: 3.5px;
    left: 11px;
  }

  &.ant-input-number-disabled {
    &::before {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  input {
    padding-left: 20px;
  }
}
