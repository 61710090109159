.resource-add {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;

  button.new-button {
    background: transparent;
    border: none;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 10px;
    max-height: 15px;
  }

  .resource-add-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    button {
      background: transparent;
    }
  }
}
