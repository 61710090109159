@import '../../atoms/scss/global';

.paymentStatus {
  background: #f6f6f6;
}

.menu-avance {
  font-size: 24px;
  color: #806f62;
}
.menu-planner {
  font-size: 24px;
  color: #806f62;
}
.menu-prod {
  font-size: 24px;
  color: #5d7aa5;
}
.menu-costo {
  font-size: 24px;
  color: #e7635a;
}
.menu-prev {
  font-size: 24px;
  color: #a5cb35;
}
.dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  background-color: #337ab778;
  color: #fff;
}
.stat-panel {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  /*   box-shadow: 0 5px 15px 0 rgb(0 4 12 / 10%) !important; */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
.stat-panel {
  background: #fff;
  border-radius: 2px;
  display: table;
  margin-bottom: 22px;
  position: relative;
  table-layout: fixed !important;
  width: 100%;
}
.ant-col-4 {
  display: block;
  -ms-flex: 0 0 16.66666667%;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
  height: 20px;
}
.padding-sm {
  padding: 10px 15px !important;
}
.stat-cell {
  display: table-cell !important;
  overflow: hidden;
  padding: 20px;
  position: relative;
}
.stat-cell,
.stat-row {
  float: none !important;
}
.flexbox-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}
.flexbox-container > div {
  width: 100%;
  text-align: end;
}
.flexbox-container > div:first-child {
  text-align: justify;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: top;
  background: linear-gradient(to bottom, white 0%, white 25%, white 50%, white 100%);
}
#gridContainer .dx-header-row .base {
  border: 1px solid #cccccc;
  background: linear-gradient(to bottom, white 0%, #f5f5f5 25%, #f5f5f5 50%, #eaeaea 100%);
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: white;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: white;
  border-top: 1px solid linear-gradient(to bottom, white 0%, #f5f5f5 25%, #f5f5f5 50%, #eaeaea 100%);
  border-bottom: 1px solid
    linear-gradient(to bottom, white 0%, #f5f5f5 25%, #f5f5f5 50%, #eaeaea 100%);
  border: 1px solid linear-gradient(to bottom, white 0%, #f5f5f5 25%, #f5f5f5 50%, #eaeaea 100%);
}
.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);

  &::after {
    border-left: 9px solid red;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);

  &::after {
    border-right: 9px solid red;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltip-message {
  background: red;
  border-radius: 3px;
  color: black;
  font-size: 0.75rem;
  line-height: 1.4;
  padding: 0.75em;
  text-align: center;
}

#gridContainer .diff {
  position: relative;
  display: table;
}

#gridContainer .inc .diff {
  color: #0b0650;
}
#gridContainer .dec .diff2,
#gridContainer .dec .diff {
  color: #f00;
}
#gridContainer {
  width: 100%;
  border-collapse: collapse;
  margin: -15px 0;
  font-size: 14px;
  font-family: sans-serif;
  min-width: 400px;
}
.long-title {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0px;
}
#gridContainer .dx-header-row {
  background: white;
  color: #444;
  border: thin solid #c9c8c8;
  white-space: nowrap;
}
#gridContainer tbody tr {
  border-bottom: 2px solid #dddddd;
}

.dx-datagrid .dx-column-lines > td {
  border-left: 0px solid #ddd;
  border-right: 1px solid #ddd;
}
#gridContainer .inc .diff:after,
#gridContainer .dec .diff:after {
  content: ' ';
  display: -ms-flex;
  display: flex;
  height: 10px;
  width: 10px;
  position: absolute;
  right: -10px;
  top: 3px;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}
#gridContainer .inc .diff:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

#gridContainer .dec .diff:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}
.separador {
  overflow: hidden;
  height: 20px;
}
.separador:after {
  content: '';
  display: block;
  width: 90%;
  height: 30px;
  margin: -30px auto 0;
  border-radius: 120px / 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
}
.advance {
  margin-right: auto;

  .new-advance-button {
    display: flex;
    align-items: center;

    i.fa {
      font-size: 22px;
      margin-right: 4px;
    }
  }
}
.dx-datagrid .dx-column-lines > td {
  border-left: 0px solid #ddd;
  border-right: 1px solid #ddd;
}
.advance-modal {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;

    .advance-container {
      border: 2px solid $dark-gray;

      .advance-header {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark-gray;

        span {
          font-weight: bold;
          font-size: 18px;
        }

        i.fa {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .advance-body {
        border-bottom: 1px solid $dark-gray;
        form {
          padding-top: 30px;
          .ant-form-item,
          l {
            padding: 0 20px;
          }

          .ant-form-item-label {
            margin-left: 20px;
          }
        }

        .advance-footer {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $dark-gray;

          button {
            width: calc(50% - 20px);
            margin: 0 10px;
          }
        }
      }

      .ant-picker {
        width: 100%;
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}
