@import '../../../atoms/scss/global';

.row-detail {
  position: relative;
  background-color: $light-gray;
  position: fixed;
  bottom: 10px;
  height: 270px;
  width: calc(100% - 20px);
  left: 10px;
  z-index: 1000;
  border-radius: 6px;
  border: 1px solid $darken-gray;

  span.anticon-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 24px;
  }
  .row-detail-content {
    position: relative;
    width: 100%;

    i.fas.fa-times {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer;
    }

    .row-detail-header {
      .inputContainer {
        margin-right: 10px;

        &:not(:first-child) {
          max-width: 120px;
        }
      }

      .ant-input-affix-wrapper {
        background: $light-yellow;
        input {
          color: #000;
        }
      }
    }

    .tables-container {
      width: 100%;
      .table-wrapper {
        border: 1px solid $darken-gray;
        width: 45%;
        min-height: 180px;
        background: #fff;

        .rs-table-row {
          .rs-table-cell-content {
            padding: 0;
          }

          .vinc-select {
            width: 100%;
          }
        }
      }
    }
  }
}
