@import '../../../atoms/scss/global';

.advance {
  margin-right: 0 !important;

  .new-advance-button {
    display: flex;
    align-items: center;

    i.fa {
      font-size: 22px;
      margin-right: 4px;
    }
  }
}

.advance-modal {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;

    .advance-container {
      border: 2px solid $dark-gray;

      .advance-header {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark-gray;

        span {
          font-weight: bold;
          font-size: 18px;
        }

        i.fa {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .advance-body {
        border-bottom: 1px solid $dark-gray;
        form {
          padding-top: 30px;
          .ant-form-item,
          l {
            padding: 0 20px;
          }

          .ant-form-item-label {
            margin-left: 20px;
          }
        }

        .advance-footer {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $dark-gray;

          button {
            width: calc(50% - 20px);
            margin: 0 10px;
          }
        }
      }

      .ant-picker {
        width: 100%;
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}
