.massAttendance {
  .attendanceTypeAndIcon {
    display: flex;
    justify-content: space-between;

    & > i {
      margin-left: 10px;
      font-size: 20px;
      position: relative;
      top: 5px;
      cursor: pointer;
      height: fit-content;
    }

    & > div {
      flex: 1;
    }
  }

  .ant-picker {
    width: 100% !important;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    button {
      min-width: 150px;
    }
  }
}

.legendsMassAttendance {
  display: flex;
  flex-direction: column;
  max-width: 300px;

  .legendContainer {
    display: flex;
    align-items: center;
    padding-top: 10px;

    & div {
      color: #fff;
      border-radius: 4px;
      margin-right: 5px;
      min-width: 30px;
      min-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
}
