.calendarList {
  display: flex;
  justify-content: center;
  text-transform: capitalize;

  .icons {
    display: flex;
    justify-content: space-around;

    > i {
      font-size: 20px;
    }
  }

  .schedule {
    width: fit-content;
    border: 1px solid;
    border-radius: 50%;
    padding: 0px 5px;
    cursor: pointer;
  }
}
