@import '../../../../atoms/scss/global';

.add-resource {
  & > button {
    background: transparent;
    border: none;
    width: 100%;
  }
}

.item-modal {
  .resource-search {
    padding: 20px;
    border-bottom: 1px solid $dark-gray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      width: calc(33.3333% - 30px);
      margin-right: 30px;

      .ant-select {
        width: 100% !important;
      }
    }

    input,
    .ant-select-selector {
      background: #fff !important;
    }
  }
}

.resources-list {
  max-width: 550px;
  margin: 0 auto 10px;
  .rs-table {
    .rs-table-row {
      .rs-table-cell {
        .rs-table-cell-content {
          padding: 0 8px !important;
          font-size: 12px;
        }
      }

      &.selected {
        .rs-table-cell {
          background: $dark-gray;
        }
      }
    }
  }
}

.remove-resource-icon {
  font-size: 18px;
  cursor: pointer;
}
