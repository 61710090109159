@import '../../../atoms/scss/global';

.profile-form {
  display: flex;
  max-width: 1200px;
  margin: 1em auto;
  width: 100%;

  .containerForm {
    width: 300px;
  }

  .containerButton {
    display: flex;
    margin-left: 3rem;

    Button {
      margin-right: 1rem;
    }
  }
}
