@import '../../atoms/scss/global';

.form-box {
  position: relative;
  padding: 2em;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 2em;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  .box-title {
    top: -17px;
    left: 30px;

    span {
      position: relative;
      bottom: 10px;
      z-index: 2;
      font-weight: bold;
      width: max-content;
      font-size: 16px;
    }

    .ant-form-item-control-input-content {
      border: 4px solid $light-gray;
    }
  }
}
