.login {
  background-image: url('../../images/login/login-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  z-index: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
  padding: 5em 0;

  .form-content {
    padding: 40px;
    color: #999;
    width: 100%;
    margin: 0 auto;
    background: transparent;
    text-align: center;

    @media all and (min-width: 768px) {
      background-color: white;
      box-shadow: -8px 8px lightgrey;
      border-radius: 25px;
      width: 550px;
    }

    img {
      height: 60px;
    }

    .form-title {
      span {
        font-size: 12px;
        color: gray;
      }

      hr.divider {
        border: 0;
        border-bottom: 4px solid #ff8b11;
        height: 0;
      }
    }

    .instructions {
      font-size: 12px;
    }

    .form {
      .row {
        display: flex;
        justify-content: center;
      }

      input {
        border-radius: 35px;
        height: 40px;
        border: none;
        text-align: center;
        background-color: #d0d0d073;
        outline: none;
        color: #707478;
        font-size: 12px;
      }

      button {
        color: white;
        background-color: #ff8b11;
        border: 1px solid #ff8b11;
        font-weight: 300;
        border: 2px solid lightgray;
        border-radius: 20px;
        font-size: 12px;
        width: 50%;

        .spinner-border {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }

        &:disabled {
          pointer-events: none;
          cursor: not-allowed;
        }

        &:hover {
          background-color: #ffc31b;
        }
      }

      .recover-password-link {
        margin-top: 10px;
      }

      p {
        font-size: 12px;
        color: #999;
        margin: 20px 0 10px 0;
      }

      a {
        border-bottom: thin dashed;
        font-size: 12px;
      }
    }
  }

  .bottom-content {
    color: gray;
    font-size: 12px;
    box-shadow: -8px 8px white;
    text-align: center;
    margin: 70px auto 0;
    width: 100%;
    background: transparent;
    padding: 30px 0;
    min-height: 250px;
    margin-top: 0;

    img {
      width: 100%;
    }

    button {
      color: white;
      background-color: #726a6a;
      border: 1px solid #726a6a;
      font-weight: 300;
      border: 2px solid lightgray;
      border-radius: 20px;
      padding: 6px 12px;
      margin-bottom: 1rem;

      &:hover {
        background-color: #8fb556;
        border: 2px solid lightgray;
      }
    }

    @media all and (min-width: 768px) {
      background-color: white;
      box-shadow: -8px 8px lightgrey;
      border-radius: 25px;
      width: 550px;
      margin: 70px auto 0;
      border-radius: 25px;
    }
  }
}
