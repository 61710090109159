@import '../../../atoms/scss/global';

.calendarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  font-size: 14px;

  .filters {
    display: flex;

    .filterContainer {
      display: flex;
      align-items: center;
      border-radius: 4px;

      .filterName {
        width: 80px;
        text-align: center;
      }

      span > i {
        color: $darken-gray-secondary;
      }
    }
  }

  .buttons {
    display: flex;

    .buttonContainer {
      position: relative;

      button {
        display: flex;
      }

      i:first-child {
        margin-right: 10px;
        font-size: 22px;
      }
    }
  }

  .marginRight {
    margin-right: 20px;
  }
}
