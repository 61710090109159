@import '../../atoms/scss/global';

.inputContainer {
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;

  .inputName {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 12px;
    padding: 0px 4px;
    z-index: 2;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      border: 1px solid #fff;
      top: 10px;
      left: 0;
      z-index: -1;
    }
  }

  span > i {
    color: $darken-gray-secondary;
  }
}
