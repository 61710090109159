@import '../../atoms/scss/global';

.home {
  padding: 2em;
  font-size: 12px !important;
  min-height: 100vh;

  & > nav {
    position: fixed !important;
  }
  .px-nav {
    z-index: 10;
    padding-top: 10px;
  }

  .navbar-toggle-icon {
    background: $primary-color !important;
    &::before,
    &::after {
      background: $primary-color !important;
      color: $primary-color !important;
    }
  }

  .px-nav-toggle-arrow {
    color: $primary-color !important;
  }

  .px-nav-toggle {
    background: $header-background-color !important;
    height: 60px !important;
  }

  nav.navbar.px-navbar {
    height: 60px !important;
    background: $header-background-color !important;
    z-index: 1000;

    .right-navbar-nav {
      width: 100%;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &::before {
        display: flex;
        content: none;
      }

      .select-container {
        min-width: 250px;
        position: relative;

        & > span {
          position: absolute;
          top: -10px;
          z-index: 2;
          left: 10px;
          font-size: 12px;
          padding: 0px 4px;
          color: #000;

          &:before {
            content: '';
            width: 100%;
            position: absolute;
            background: #fff;
            height: 1px;
            top: 10px;
            right: 0;
            z-index: -1;
          }
        }

        & > div {
          width: 100%;
        }
      }

      .left-menu {
        display: flex;
        align-items: center;
        text-align: center;

        h4 {
          color: #1e2125;
          text-align: center;
          margin: auto;
          font-weight: bold;
        }

        .logo {
          width: 150px;
          height: auto;
          position: relative;
          bottom: 2px;
        }

        .select-container {
          height: 28px;
          display: flex;
          margin-left: 15px;

          @media all and (max-width: 767px) {
            display: none;
          }

          select {
            color: #1e2125;

            @media all and (min-width: 768px) {
              width: 256px;
            }
          }
        }
      }

      .logout-menu {
        max-height: 40px;
        width: 120px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;
          padding: 1em;
          position: relative;
          background: transparent;
          border: none;

          span {
            color: $logout-button-color;
            font-family: verdana;
            font-size: 'XX-Small';

            &::after {
              display: none;
            }

            @media all and (max-width: 767px) {
              display: none;
            }
          }
        }

        i.fa.fa-sign-out,
        i.fa.fa-question-circle {
          margin-right: 5px;
          color: $primary-color;
        }

        .logout-menu-list {
          position: absolute;
          right: 0;
          top: 46px;
          background: $darken-gray;
          border-color: $darken-gray;
          margin-top: 2px;
          padding: 5px;
          border-radius: 2px;
          min-width: 160px;

          a {
            display: flex;
            align-items: center;
            width: max-content;
            color: #555;
            text-decoration: none;
            height: 32px;
            padding: 5px 18px;
            margin: 0;
            width: 100%;

            &:hover {
              background: $light-gray;
            }

            i.fa {
              color: #000;
              margin-right: 5px;
            }
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .px-nav {
    .px-nav-dropdown-menu-title {
      font-size: 14px;
    }

    li.px-nav-item {
      & > a {
        color: #626b7a;

        span {
          font-size: 14px;
          position: relative;
          bottom: 3px;
        }

        i {
          margin-right: 8px;
          font-size: 23px;

          &.sandybrown {
            color: $primary-color;
            &:hover {
              color: $primary-color;
            }
          }
        }

        &:hover {
          color: #1e2125;
        }
      }
    }
  }

  .px-content {
    background-color: $layout-background-color;
    padding-bottom: 0;
  }
}

.spinner-wrapper {
  background-image: url('../../images/login/login-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  z-index: 0;
  height: 100vh;
  display: flex;
}
