$primary-color: #007bff;
$white: #fff;
$header-background-color: #e9ecef;
$logout-button-color: #fff;
$layout-background-color: #fff;
$light-blue: #9ccbff;
$gray: #808080;
$light-gray: #f2f2f2;
$dark-gray: #999999;
$darken-gray-secondary: #d9d9d9;
$darken-gray: #cccccc;
$light-yellow: #ffffc4;
$purple-light: #f7f3f8;
$light-green: #6aa84f;
$green: green;
$light-orange: #ffcc80;
$orange: #f0950c;
