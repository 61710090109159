@import '../../../atoms/scss/global';

.date-picker-editable-cell {
  padding: 0 !important;

  &.editable {
    background-color: $light-yellow;
    cursor: pointer;
  }

  .date-picker-modal-toggle {
    width: 100%;
    height: 100%;
    text-align: center;
    min-width: 80px;
    min-height: 24px;
  }

  &.with-error {
    background-color: $light-orange;
    cursor: default;
  }
}

.date-picker-editable-cell-modal {
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    & > .ant-picker {
      width: 100%;
    }
  }
}

.ant-modal-mask {
  z-index: 3000;
}
