.selectContainer {
  position: relative;

  .selectLabel {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 12px;
    padding: 0px 4px;
    z-index: 2;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      border: 1px solid #fff;
      top: 10px;
      left: 0;
      z-index: -1;
    }
  }
}
