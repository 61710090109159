@import '../../../atoms/scss/global';

.company-list {
  display: flex;
  align-items: center;
  justify-content: center;

  .fa {
    cursor: pointer;
    font-size: 22px;
    margin-right: 4px;
  }

  .edit-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-buttons {
    display: flex;
    margin-bottom: 1em;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
    }

    .search {
      max-width: 250px;
      margin-left: 5px;
    }
  }
}

.spinner-border {
  margin: auto;
}

.company-form-modal {
  .ant-modal {
    top: 10px;
  }
}

.containerProfileTable {
  max-width: 100%;

  .containerTable {
    margin: auto;
    width: 602px;
    box-shadow: 5px 18px 15px -19px #646161;

    .buttonNewProfile {
      display: flex;
      width: 100%;
      margin: auto;
      margin-bottom: 1rem;

      button {
        margin-left: auto;
        margin-right: 1rem;
      }
    }
  }

  .iconSpace {
    margin-right: 0.5rem;
  }

  .iconSize {
    font-size: 1.5rem;
    margin: 0.5rem;
  }
}
