@import '../../atoms/scss/global';

.jobCost {
  .top-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0 !important;
  }

  .input-info {
    margin-right: 10px;
    .ant-input-suffix {
      display: none;
    }
    .ant-input-affix-wrapper {
      max-width: 165px !important;
    }

    input {
      color: #000;
    }
  }

  .jobcost-search {
    max-width: 160px;
    margin-left: 10px;
  }
}
