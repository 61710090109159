.spin {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 30px;
}

.paddingInUrl {
  padding: 15px;
}
.k-grid th,
.k-grid td {
  padding: 0px 0px;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
