@import '../../../atoms/scss/global';

.payment-state-management {
  .viewoptions-payment {
    z-index: 5;
  }
}

input#file {
  padding: 0;
  margin: 0;
}
