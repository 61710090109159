.clients {
  background-image: url('../../images/login/login-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  color: #999;

  &.flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    color: #323232;
    padding: 2em;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 60px;

    .box {
      display: flex;
      margin: 60px auto 30px;
      width: 650px;
      table-layout: fixed;
      background-color: floralwhite;

      & > div:nth-child(1) {
        background: rgba(234, 127, 20, 0.2);
        width: 40%;
        padding: 30px;

        h3 {
          margin-top: 18px;
          margin-bottom: 9px;
          color: rgb(85, 85, 85);
        }

        span {
          padding: 7px 0;
          color: #323232;
          font-size: 13px;
          font-weight: 300;
        }

        img {
          height: 69px;
        }
      }
      & > div:nth-child(2) {
        width: 60%;
        padding: 30px;
        background: #fff;

        .select-title {
          color: #777;
          display: block;
          font-size: 15px;
          font-style: italic;
          margin-bottom: 25px;
          position: relative;
          text-align: center;

          &:before {
            background: #e4e4e4;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
            top: 50%;
          }

          span {
            background: #fff;
            display: inline-block;
            padding: 0 20px;
            position: relative;
          }
        }

        ul {
          list-style-type: circle;
          max-height: 400px;
          overflow: auto;

          li {
            button {
              background: none;
              border: none;
              color: #4083a9;
              font-size: 13px;

              &:hover {
                color: #205f82;
              }
            }
          }
        }

        button.cancel {
          color: #555555;
          background-color: #efefef;
          border-radius: 100px;
          font-size: 13px;
          font-weight: 600;
          line-height: 19px;
          padding: 10px 40px;
          border: none;

          &:hover {
            background-color: #444;
            color: #fff;
          }
        }
      }
    }

    .bottom-text {
      display: flex;
      flex-direction: column;
      margin: 20px auto;
      padding: 20px 0;
      font-size: 14px;
      text-align: center;
      color: black;

      a {
        color: black;
      }
    }
  }
}

.container-welcome {
  background: rgb(248, 245, 245);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 95%;
    height: 100%;
  }
}
