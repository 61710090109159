.form-body.new-chapter {
  .parent-name {
    padding: 10px 20px 0;
    font-size: 12px;
    font-weight: bold;
  }

  form.ant-form.ant-form-vertical {
    padding-top: 15px;
  }
  &.saving {
    form {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
