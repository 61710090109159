@import '../../atoms/scss/global';

.company-form {
  max-width: 600px;
  margin: 1em auto;

  .consortium-toggle {
    display: flex;
    flex-direction: column;

    .consortium-add {
      margin-top: 1em;
      display: flex;
      flex-direction: column;

      button.new-button {
        background: transparent;
        border: none;
        margin-right: auto;
        margin-bottom: 10px;
      }

      .ant-form-item-explain.ant-form-item-explain-error {
        font-size: 10px;
        max-height: 15px;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      min-width: 200px;
    }
  }

  .custom-field {
    display: flex;
    position: relative;
    min-height: 30px;
    line-height: 28px;

    &.gray {
      background: $darken-gray;
      font-weight: bold;
      font-size: 12px;
    }

    .ant-input-number {
      width: 100%;
    }

    & > div {
      border: 1px solid $darken-gray-secondary;
      width: 50%;
      padding: 0 10px;

      .ant-form-item {
        margin: 0;
        * {
          border: none;
          outline: none;
          box-shadow: none;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .fa.fa-trash {
      position: absolute;
      right: -18px;
      top: 10px;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .upload-form-item {
    position: relative;
    .avatar-uploader {
      width: 100%;
      display: grid;
      min-height: 32px;

      .upload-container {
        display: flex;
        align-items: center;

        .image-uploader {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border: 1px solid $darken-gray-secondary;
          height: 32px;

          & > div {
            margin-left: 4px;
          }
        }

        img {
          max-width: 100px;
          margin-top: 20px;
        }
      }

      &.loading {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    i.fa-trash {
      position: absolute;
      cursor: pointer;
      top: -3px;
      left: -10px;
    }
  }

  .add-field-button {
    margin-top: 10px;
  }
}
