@import '../../../atoms/scss/global';

.levels {
  .rs-table {
    font-size: 13px;
    .rs-table-row {
      &.total {
        .rs-table-cell-group.rs-table-cell-group-scroll {
          position: relative;
          & > div {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              border-top: 2px solid #000;
            }
          }
        }
      }

      &.rs-table-row-header {
        font-weight: bold;

        .rs-table-column-group-header-content,
        .rs-table-column-group-cell-content,
        .rs-table-cell-content {
          padding: 3px 5px !important;
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .rs-table-cell-header,
    .rs-table-cell:not(.editable) {
      .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .rs-table-cell {
      .level-type {
        font-size: 12px;
        word-break: break-word;
      }
      .rs-table-cell-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      &.editable {
        background-color: $light-yellow !important;
      }

      .add-sub-level {
        color: $primary-color;
        font-size: 22px;
        cursor: pointer;
        position: relative;
        left: 7px;
      }
    }

    .name-type-pnf {
      .editable-cell-value-wrap {
        text-align: left;
        justify-content: flex-start;
      }

      input {
        width: 100%;
      }
    }

    .rs-table-cell.table-editable-cell {
      &.editable {
        cursor: pointer;
        &:not(.edited) {
          background-color: $light-yellow !important;
        }
      }
      &.editable.edited {
        background-color: $light-green !important;
      }
    }
  }
}

.table-editable-cell {
  &.editable {
    cursor: pointer;
    &:not(.edited) {
      background-color: $light-yellow !important;
    }
  }
  &.editable.edited {
    background-color: $light-green !important;
  }
}
