.payment-states-list {
  margin-top: -20px;
  .first-buttons-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }

    & > div:first-child {
      margin-right: auto;
    }
  }

  .table-wrapper {
    .rs-table {
      width: 100vw !important;
      .rs-table-cell {
        .rs-table-cell-content {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      .fa-external-link {
        color: initial;
        font-size: 1.3rem;
        color: #ffffff;
      }

      .color-link {
        color: black;
      }
    }
  }
}
