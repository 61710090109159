@import '../../../atoms/scss/global';

.modalItemDetails {
  .ant-modal {
    top: 10px;
  }

  .spinner {
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    font-size: 40px;
    top: 0;
    left: 0;
  }
}

.itemDetails {
  .formRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .ant-form-item-control-input {
      min-width: 250px;

      .ant-input {
        background-color: #fff;
        color: #000;
        padding-right: 10px;
        text-align: end;
      }
    }

    .percentage-form-item {
      .ant-col.ant-form-item-label {
        left: 37px;
      }

      .prefix-label {
        height: 30px !important;
      }
    }

    .editableInput {
      .ant-input {
        background-color: rgb(255, 255, 196) !important;
      }
    }

    .with-prefix {
      padding: 0;
      .ant-input-prefix {
        top: 0;
        left: 0;
        color: #000;
        margin-right: 0px;

        .prefix-label {
          padding: 4px 8px;
          background: $darken-gray-secondary;
          display: flex;
          height: 100%;
          min-width: 25px;
        }
      }
    }
  }

  .switchContainer {
    display: flex;

    & > span {
      margin-left: 10px;
    }

    .ant-switch-checked {
      background-color: $light-green;
    }
  }

  .carouselAndCoordinates {
    display: flex;
    margin-top: 15px;

    .carouselContainer {
      position: relative;
      width: 550px;

      .withoutEvidence {
        width: 550px;
        height: 400px;
        background-color: lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrow {
        color: #fff;
        font-size: 30px;
        position: absolute;
        top: 50%;
        z-index: 1;
        cursor: pointer;
      }

      .arrowRight {
        left: 95%;
      }
    }

    .coordinatesContainer {
      padding-left: 10px;

      .title {
        font-weight: bold;
        padding-bottom: 15px;
      }

      .ant-form-item-control-input {
        .ant-input-disabled {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;

    .ant-form-item {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

    button {
      min-width: 300px;
    }

    .orangeButton {
      background-color: #f87c1d !important;
      border: none;
    }
  }
}
