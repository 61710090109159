@import '../../../atoms/scss/global';

.attendanceControlHeader {
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  @media (max-width: 1500px) {
    flex-direction: column;
  }
  .iconSpace {
    margin-right: 0.5rem;
  }

  .ant-tooltip-inner {
    color: black !important;
    background-color: white !important;
  }

  .ant-select-selector {
    background-color: #fadcab !important;
  }
  .ant-tooltip-placement-bottom {
    border-bottom-color: white !important;
  }

  .filters {
    display: flex;

    .filterContainer {
      display: flex;
      align-items: center;
      border-radius: 4px;

      .filterName {
        max-width: 80px;
        text-align: center;
      }

      span > i {
        color: $darken-gray-secondary;
      }
    }
  }

  .buttons {
    display: flex;

    @media (max-width: 1500px) {
      padding-top: 20px;
    }

    .buttonContainer {
      position: relative;

      button {
        display: flex;
      }

      i:first-child {
        margin-right: 10px;
        font-size: 22px;
      }
    }
  }

  .marginRight {
    margin-right: 20px;
  }

  .buttonIcon {
    padding: 0% 1% 10px 1% !important;
  }

  .ant-select-selector {
    background-color: white !important;
  }

  .cancelButton {
    background-color: gray !important;
  }
  .cancelButton :hover {
    color: white !important;
  }
  .selected {
    width: 17rem;
  }
}
