@import '../../../../atoms/scss/global';

.add-payment-state {
  width: max-content;

  .button-wrapper {
    display: flex;

    .inputContainer {
      min-width: 140px;

      .ant-input-suffix {
        display: none;
      }
    }

    button {
      cursor: pointer;
      margin: 0;
    }
  }
}

.currency {
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: -10px;
  font-weight: bold;
}

.edit-payment-state-modal {
  .ant-input,
  .ant-form-item-control-input-content {
    background-color: $light-yellow !important;
  }
}
