@import '../../atoms/scss/global';

.baseRate {
  .first-buttons-col {
    .view-options-rate {
      margin-right: 1%;
    }
  }

  .input-search {
    margin-right: auto;
    margin-left: 1%;
  }

  .rs-table {
    .rs-table-row {
      .ant-select {
        min-width: 120px;
      }
      .type-select .ant-select-selector {
        background-color: $light-yellow;
        border: none;
        padding: 0;
      }
      .plus-cell {
        font-size: 22px;
        cursor: pointer;
        color: $primary-color !important;
      }

      div.rs-table-cell-content {
        max-width: 100% !important;
        padding: 0;
      }

      input#PRECIO_ITEM_PROY.ant-input.editable {
        width: 100% !important;
      }
    }
  }
}
