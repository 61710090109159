.table-editable-cell {
  z-index: 1;
  .editable-form-item-wrapper {
    display: flex;
    align-items: center;

    .editable-form-item {
      max-height: 25px;
      min-height: 25px;
      margin: 0;

      .ant-form-item-control-input {
        max-height: 25px;
        min-height: 25px;

        .ant-form-item-control-input-content {
          margin: 0;
          padding: 0;

          input {
            height: 25px;
            font-size: 12px;
            margin: 0;
            min-width: 82px;
          }
        }
      }

      .ant-form-item-explain.ant-form-item-explain-error {
        div {
          font-size: 10px;
        }
      }
    }
  }

  .value-mask {
    position: absolute;
    left: 0;
    text-align: right;
    width: 100%;
    z-index: 2;
  }

  .editable-cell-value-wrap {
    i.fa-edit {
      position: absolute;
      right: 8px;
      font-size: 18px;
      top: 6px;
    }
  }
  .editable-form-item-wrapper {
    .max-button {
      z-index: 10;
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 4px;
    }

    .value-mask.PRC_AVA_AUX {
      text-align: center;
    }
  }

  .editable-cell-value-wrap,
  .editable-form-item-wrapper {
    position: relative;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    i.fa-edit {
      font-size: 18px;
    }

    input {
      height: 25px;
    }
  }

  .max-button {
    height: 25px;
    padding: 4px;
    font-size: 11px;
    margin-left: 2px;
  }
}
