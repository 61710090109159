.project-list {
  display: flex;
  align-items: center;
  justify-content: center;

  .fa {
    cursor: pointer;
    font-size: 22px;
    margin-right: 4px;
  }

  .edit-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-buttons {
    display: flex;
    margin-bottom: 1em;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .search {
      max-width: 250px;
      margin-left: 5px;
    }
  }

  .ant-table-thead > tr > th,
  .header-table-color {
    background: #007bff !important;
    color: #fff !important;
    font-weight: bold;
  }
}

.spinner-border {
  margin: auto;
}

.project-form-modal {
  .ant-modal {
    top: 10px;
  }
}
