@import '../../atoms/scss/global';

.cost-center {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: -40px;

  .cost-center-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 860px;
    margin: auto;
  }

  .rs-table {
    box-shadow: 0px 6px 10px -5px #646161;

    .edit-cell {
      i {
        font-size: 20px;

        &.fa-pencil {
          margin-right: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    .first-level {
      background: $darken-gray !important;
      div:not(.ant-switch-handle) {
        background: $darken-gray !important;
      }

      .rs-table-cell {
        border-width: 0;
      }
    }

    .rs-table-cell-content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .level {
      &:hover {
        .rs-table-cell {
          background: rgba(26, 144, 255, 0.3) !important;
        }
      }
    }

    .second-level {
      background: $light-gray !important;
      div:not(.ant-switch-handle) {
        background: $light-gray !important;
      }
      .rs-table-cell {
        border-width: 0;
      }
    }

    .without-children {
      .rs-table-cell-expand-wrapper {
        display: none !important;
      }
    }
  }
}
