@import '../../atoms/scss/global';

.tree-filter {
  position: relative;
  z-index: 3000;
  border-radius: 4px;

  .options-container {
    position: absolute;
    left: 0;
    border: 1px solid $dark-gray;
    background: #fff;
    min-width: 340px;
    border-radius: 4px;

    .options-header {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dark-gray;

      span {
        font-weight: bold;
        font-size: 18px;
      }

      i.fa {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 20px 8px 8px;
      margin-top: 10px !important;

      button {
        width: calc(50% - 8px) !important;
      }
    }
  }
  .ant-tree-list {
    max-height: 300px;
    overflow-y: auto;

    .ant-tree-treenode {
      border-bottom: 1px solid $dark-gray;
      width: 100%;
      padding: 3px 0 !important;
      padding-left: 8px !important;

      i.fa {
        font-size: 16px;
        margin-right: 8px;
      }

      &.ant-tree-treenode-switcher-open {
        i.fa {
          transform: rotate(180deg);
        }
      }

      .rs-check-item:not(.rs-checkbox-disabled):hover {
        background-color: transparent !important;
      }

      .rs-checkbox-wrapper .rs-checkbox-inner::before {
        border-radius: 2px;
      }
      .ant-tree-node-selected {
        background: transparent !important;
      }
      .ant-tree-node-content-wrapper {
        font-size: 12px;
        color: #444;
        font-weight: bold;
        line-height: 16px;
        position: relative;
        top: 2px;
      }
    }
  }
}
