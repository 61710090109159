.left-tools {
  display: flex;
  margin-right: auto;
  align-items: flex-end;

  & > div {
    display: flex;

    i.fa {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1890ff;
      min-width: 30px;
      color: #fff;
      cursor: pointer;
    }
  }

  & > * {
    margin-right: 10px;
    max-width: 140px;
  }

  .number-ep {
    max-width: 120px;
  }

  .ant-input-affix-wrapper-disabled {
    background-color: #fff !important;
  }
}
