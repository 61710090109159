@import '../../atoms/scss/global';

.view-options {
  position: relative;
  margin-left: auto !important;
  z-index: 1;

  .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 18px;
    }

    i.fa {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .options-container {
    position: absolute;
    right: 0;
    border: 2px solid #eee;
    background: #fff;
    min-width: 280px;

    .ant-radio-group {
      margin: 10px auto;
      display: flex;
      flex-direction: row;
    }

    & > div {
      border-bottom: 1px solid #eee;
      padding: 10px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      button {
        min-width: calc(50% - 8px);
      }
    }
  }
}
