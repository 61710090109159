@import '../../atoms/scss/global';

.form-box {
  position: relative;
  padding: 2em;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 2em;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  .box-title {
    top: -17px;
    left: 30px;

    span {
      position: relative;
      bottom: 10px;
      z-index: 2;
      font-weight: normal;
      width: max-content;
      font-size: 16px;
    }

    .ant-form-item-control-input-content {
      border: 4px solid $light-gray;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;

  button {
    min-width: 300px;
  }
}

.pnf {
  margin: 0 35px 0 35px;
  .first-buttons-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 !important;
    button {
      margin-left: 10px;
    }
  }

  .ant-tabs-content-holder {
    padding: 12px;
    background: white;
    border: 1px solid #f2f2f5;
    border-top: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab {
    min-width: 250px;
    border: 1px solid $darken-gray;
    border-bottom: none;
    margin: 0;
    height: 0px;
    border-right: 15px solid transparent;
    border-bottom: 35px solid $darken-gray;
    border-top: 0;
    border-left: 0;
    display: flex;
    justify-content: center;

    .ant-tabs-tab-btn {
      position: absolute;
      top: 30px;
    }

    &.ant-tabs-tab-active {
      border-color: #f2f2f5;
      border-right-color: transparent;
    }
  }
}
