.datePickerContainer {
  position: relative;

  .datePickerLabel {
    position: absolute;
    top: -10px;
    z-index: 2;
    background-color: #fff;
    left: 10px;
    font-size: 12px;
    padding: 0px 4px;
  }
}
