@import '../../atoms/scss/global';
.btnGoogle {
  background-color: #db3236;
  border: 0px transparent;
  text-align: center;
}
.form-box {
  position: relative;
  padding: 2em;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 2em;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  .box-title {
    top: -17px;
    left: 30px;

    span {
      position: relative;
      bottom: 10px;
      z-index: 2;
      font-weight: normal;
      width: max-content;
      font-size: 16px;
    }

    .ant-form-item-control-input-content {
      border: 4px solid $light-gray;
    }
  }
}

.btnGoogle:hover {
  background: #db3236;
  opacity: 0.6;
}

.ConCuenta {
  padding: 0px 34px;
}
.switchContainer {
  display: flex;
  margin-top: -20px;
  margin-bottom: 20px;
  & > span {
    margin-left: 10px;
  }

  .ant-switch-checked {
    background-color: $light-green;
  }
}
.newWorkingDay {
  .ant-modal {
    top: 10px;
  }

  .formContainer {
    display: flex;
    justify-content: space-around;

    .ant-form-item {
      min-width: 230px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;

    button {
      min-width: 300px;
    }
  }

  .editableInput {
    background-color: $light-yellow !important;
    padding: 0px 4px;
    max-width: 90px;
  }

  .notEditableInput {
    min-width: 90px;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ant-picker-ranges {
    padding: 4px 16px !important;
  }

  .ant-select {
    width: -webkit-fill-available;
  }

  .ant-picker-ranges {
    padding: 4px 16px !important;
  }
}
@import '../../atoms/scss/global';

.pnf {
  margin: 0 35px 0 35px;
  .first-buttons-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 !important;
    button {
      margin-left: 10px;
    }
  }
  .row-cuenta-vinculado {
    text-align: center;
    justify-content: center;
    margin: 20px 100px 20px 35px;
  }
  .pnf-date {
    margin-right: auto;
    .ant-select {
      min-width: 120px;
    }
  }
  .react-tel-input .flag-dropdown.open {
    z-index: 3;
    background: #fff;
    border-radius: 3px 0 0 0;
  }
  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    z-index: 0;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100%;
    outline: none;
  }
  .ant-tabs-content-holder {
    padding: 12px;
    background: white;
    border: 1px solid #f2f2f5;
    border-top: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab {
    min-width: 250px;
    border: 1px solid $darken-gray;
    border-bottom: none;
    margin: 0;
    height: 0px;
    border-right: 15px solid transparent;
    border-bottom: 35px solid $darken-gray;
    border-top: 0;
    border-left: 0;
    display: flex;
    justify-content: center;

    .ant-tabs-tab-btn {
      position: absolute;
      top: 30px;
    }

    &.ant-tabs-tab-active {
      border-color: #f2f2f5;
      border-right-color: transparent;
    }
  }
}
.accounts-cost-modal {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    display: none;
  }
}
