.set-valorization-type {
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    button {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}

.editable-name-cell {
  padding: 0 !important;

  input {
    background: #ffffc4;
  }
}
