@import '../../atoms/scss/global';

.baseBudgetOP {
  margin-top: -20px;
  .first-buttons-col .edit-buttons {
    justify-content: flex-end;
  }

  .view-option-GG-00 {
    margin-right: 1%;
  }

  .rs-table {
    .rs-table-row {
      .ant-select {
        min-width: 120px;
      }
      .type-select .ant-select-selector {
        background-color: $light-yellow;
        border: none;
        padding: 0;
      }
      .plus-cell {
        font-size: 22px;
        cursor: pointer;
        color: $primary-color !important;
      }
      .trash-cell {
        font-size: 22px;
        cursor: pointer;
      }

      div.rs-table-cell-content {
        padding: 0;
      }
    }
  }
}
