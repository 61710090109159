@import '../../atoms/scss/global';

.OverheadProjectionV2 {
  margin-top: -20px;
  .first-buttons-col .edit-buttons {
    justify-content: flex-end;
    width: inherit;
  }
  .date-picker-wrapper-project {
    margin-right: auto;
    margin-left: 1%;
  }

  .viewOptions-GG-estudio {
    margin-right: 1%;
  }

  .rs-table {
    z-index: 1;
    .rs-table-row {
      .ant-select {
        min-width: 120px;
      }
      .type-select .ant-select-selector {
        background-color: $light-yellow;
        border: none;
        padding: 0;
      }
      .plus-cell {
        font-size: 22px;
        cursor: pointer;
        color: $primary-color !important;
      }

      .trash-cell {
        font-size: 22px;
        cursor: pointer;
      }

      div.rs-table-cell-content {
        padding: 0;
      }
    }
  }
}
