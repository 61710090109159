@import '../../../atoms/scss/global';

.scheduleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  font-size: 14px;
  padding-bottom: 40px;

  .buttons {
    display: flex;

    .buttonContainer {
      position: relative;

      button {
        display: flex;
      }

      i:first-child {
        margin-right: 10px;
        font-size: 22px;
      }
    }
  }

  .marginRight {
    margin-right: 20px;
  }
}
