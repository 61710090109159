@import '../../../atoms/scss/global';

.project-form {
  max-width: 600px;
  margin: 2em auto;

  .ant-picker {
    width: 100% !important;
  }

  .hidden {
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .contract-amount-form-item {
    .ant-col.ant-form-item-label {
      left: 65px;
    }

    .prefix-label {
      min-width: 53px !important;
      justify-content: center;
    }
  }

  .percentage-form-item {
    .ant-col.ant-form-item-label {
      left: 37px;
    }

    .prefix-label {
      height: 30px !important;
    }
  }

  .with-prefix {
    padding: 0;
    .ant-input-prefix {
      top: 0;
      left: 0;
      margin-right: 11px;

      .prefix-label {
        padding: 4px 8px;
        background: $darken-gray-secondary;
        display: flex;
        height: 100%;
        min-width: 25px;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      min-width: 150px;
    }
  }

  .custom-field {
    display: flex;
    position: relative;
    min-height: 30px;
    line-height: 28px;

    &.gray {
      background: $darken-gray;
      font-weight: bold;
    }

    &.values {
      & > div:first-child,
      & > div:first-child * {
        background: #eeeeee;
      }
      & > div:nth-child(2),
      & > div:nth-child(2) * {
        background: $light-yellow;
      }
    }

    & > div {
      border: 1px solid $darken-gray-secondary;
      width: 50%;
      padding: 0 10px;

      .ant-form-item {
        margin: 0;
        * {
          border: none;
          outline: none;
          box-shadow: none;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .fa.fa-trash {
      position: absolute;
      right: -18px;
      top: 10px;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .additional-parameters {
    margin-top: 1em;
    display: flex;
    flex-direction: column;

    button.new-button {
      background: transparent;
      border: none;
      margin-right: auto;
      margin-bottom: 10px;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 10px;
      max-height: 15px;
    }
  }

  .add-field-button {
    margin-top: 10px;
  }
}
