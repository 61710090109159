.new-advance-modal {
  .date-picker-wrapper {
    display: flex;
    justify-content: center;
    min-width: 500px;
    margin-bottom: 20px;
  }
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    button {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}
