@import '../../../atoms/scss/global';

.new-item {
  .new-item-button {
    display: flex;
    align-items: center;

    i.fa {
      font-size: 22px;
      margin-right: 4px;
    }
  }
}

.form-body {
  &.saving {
    form {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  input:not(disabled) {
    background: rgb(255, 255, 196);
  }
  .ant-select-selector {
    background: rgb(255, 255, 196) !important;
  }

  .ant-form-item-control-input {
    input[disabled] {
      background: #fff;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-input-number {
    width: 100%;
  }

  .input-with-unit {
    &::before {
      content: '$';
      position: absolute;
      top: 3.5px;
      left: 11px;
    }

    &.ant-input-number-disabled {
      &::before {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    input {
      padding-left: 20px;
    }
  }

  .form-row {
    display: flex;
    width: 100%;
    margin: 0;

    & > div {
      width: 50%;
      margin-bottom: 10px;
    }

    &.four {
      & > div {
        width: 25%;
        margin-bottom: 24px;
      }
    }
  }
}

.resources-error div {
  font-size: 14px;
}

.new-item-resources-table {
  .rs-table {
    .rs-table-row {
      &.rs-table-row-header {
        .rs-table-cell {
          background: $dark-gray;
          color: #000;
        }
      }
      .rs-table-cell {
        .rs-table-cell-content {
          padding: 0 8px !important;
          font-size: 11px;
        }
      }
    }
  }
}

.tree-select-node {
  display: flex;
  align-items: center;
  justify-content: space-between;

  i.fa {
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
  }
}

.caps-dropdown {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid $darken-gray-secondary;

  .ant-select-tree-list {
    .ant-select-tree-treenode {
      padding: 2px 0 !important;
      border-bottom: 1px solid $darken-gray-secondary !important;
    }
  }
}

.item-modal {
  .ant-modal {
    top: 10px;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;

    .modal-container {
      border: 2px solid $dark-gray;

      .modal-header {
        padding: 8px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark-gray;

        &::before,
        &::after {
          display: none;
        }

        span {
          font-weight: bold;
          font-size: 18px;
        }

        i.fa {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .form-body {
        border-bottom: 1px solid $dark-gray;
        form {
          padding-top: 30px;
          .ant-form-item,
          l {
            padding: 0 20px;
          }

          .ant-form-item-label {
            margin-left: 20px;
          }
        }

        .modal-footer {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $dark-gray;

          button {
            width: calc(50% - 20px);
            margin: 0 10px;
          }
        }
      }

      .ant-picker {
        width: 100%;
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}
