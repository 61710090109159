@import '../../atoms/scss/global';

.budget-control {
  margin-top: -20px;
  .budget-control-col {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .loader-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        font-size: 30px;
        margin-top: 80px;
      }
    }
  }
}

.accounts-cost-modal {
  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    display: none;
  }
}

.buttonModalforecast {
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 1rem;
  }
}

.container-icons-edit {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;

  .delete_icon {
    margin-right: 0.5rem;
  }
}
