.image-zoom-modal-wrap {
  z-index: 1002 !important;
  min-height: calc(100vh - 200px);

  .ant-modal-close-icon {
    color: #fff;
  }
}
.image-zoom-modal {
  top: 20px !important;
  width: 100vw !important;
  .ant-modal-content {
    background: #000 !important;
    .ant-modal-body {
      min-height: calc(100vh - 60px);
      position: relative;
      display: flex;

      .loader {
        color: #fff !important;
        font-size: 40px;
      }
      img {
        margin: 0 auto;
      }
      .info {
        position: absolute;
        top: 20px;
        color: #fff;
        display: flex;
        flex-direction: column;
      }

      i.fas {
        font-size: 50px;
        position: absolute;
        top: 50%;
        color: #fff;
        cursor: pointer;

        &.fa-chevron-right {
          right: 20px;
        }
      }
    }
  }
}
