@import '../../../atoms/scss/global';

.legend {
  position: relative;
  z-index: 1001 !important;

  .legend-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 18px;
    }

    i.fa {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .legendButton {
    svg {
      font-size: 22px;
    }
  }

  .legend-container {
    position: absolute;
    right: 0;
    border: 2px solid #eee;
    background: #fff;
    min-width: 450px;
    font-size: 12px;

    & > div {
      text-align: center;
      border-bottom: 1px solid #eee;
      padding: 10px;
    }
    .info-icons {
      margin: 0 !important;
    }
    .legend-content {
      & > div {
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 50%;
        }
      }
      .legend-color {
        width: 15px;
        height: 15px;
        margin: auto;
      }

      .legend-color-text {
        text-transform: capitalize;
      }

      .legend-description {
        font-size: 11px;
        font-weight: 500;
        text-align: left;
      }
    }
  }
}
