.container-modal {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  .container-items {
    padding: 0rem 3rem 0rem 3rem;

    .checkbox-container {
      .ant-checkbox-wrapper {
        font-size: 11px;
        font-weight: normal !important;
      }
    }
  }

  .top-select-modal {
    .ant-select {
      min-width: 180px;
      width: 100% !important;
    }

    &.long {
      .ant-select {
        min-width: 220px;
      }
    }
  }
}

.modal-management-position {
  position: fixed;
  top: 10px !important;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.control-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 3rem 3rem 3rem;
  button {
    width: 100%;
  }

  .color-button {
    background-color: #f0950c !important;
    color: #fff;
  }
}
