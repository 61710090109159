.edit-modal {
  top: 10px !important;
  .w-40 {
    width: 40%;
  }
  .user-modal-select {
    width: 260px;
  }

  .buttonUserModal {
    display: flex;
    margin: 1rem;

    .buttonUser {
      margin-left: auto;

      button {
        margin-right: 1rem;
      }
    }
  }
}
