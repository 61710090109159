@import '../../atoms/scss/global';

.overheadProjection {
  margin-top: -20px;
  .date-picker-wrapper {
    margin-right: auto;
    margin-left: 1%;
  }
  .first-buttons-col .edit-buttons {
    justify-content: flex-end;
    width: inherit;
  }

  .rs-table {
    .rs-table-row {
      .plus-cell {
        font-size: 22px;
        cursor: pointer;
        color: $primary-color !important;
      }
    }
  }
  .view-option-GG-Proyectado {
    margin-right: 1.2%;
  }
}
