.logout {
  background-image: url('../../images/login/login-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  color: #999;
}
