.scheduleList {
  display: flex;
  justify-content: center;
  text-transform: capitalize;

  .icons {
    display: flex;
    justify-content: space-around;

    > i {
      font-size: 20px;
    }
  }
}
