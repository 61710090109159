.list {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px;
  margin-left: 20px;

  .containerList {
    max-width: 1000px;
    width: 100%;

    .headerList {
      display: flex;
      font-weight: bold;
      min-height: 45px;
      font-size: 18px;
      text-transform: capitalize;

      > div {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .headerArea {
        border-right: 1px solid #d8dddf;
        border-left: 1px solid #d8dddf;
        border-top: 1px solid #d8dddf;
        width: calc(30% + 1px);
      }

      .headerPosition {
        border-right: 1px solid #d8dddf;
        border-top: 1px solid #d8dddf;
        width: 60%;
      }

      .headerCost {
        border-right: 1px solid #d8dddf;
        border-top: 1px solid #d8dddf;
        width: 10%;
      }
    }

    .contentList {
      display: flex;
      border-bottom: none;
      border-top: none;
      background-color: #ffffff;
      min-height: 45px;

      > div {
        text-align: center;
      }

      .contentArea {
        width: 30%;
        padding: 5px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 12px;

        div:first-child {
          white-space: nowrap;
          overflow: hidden;
        }

        .floatIcons {
          position: absolute;
          font-size: 20px;
          left: -70px;
          border: 1px solid #d8dddf;
          width: 70px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 45px;
          padding: 5px;
        }

        .arrowIcon {
          background: #d8dddf;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: -31px;
          cursor: pointer;
          height: 45px;
        }
      }

      .withoutBorder {
        border: none;
      }

      .withTopAndBottomBorder {
        border-bottom: 1px solid #d8dddf;
        border-top: 1px solid #d8dddf;
      }

      .contentPosition {
        border-right: 1px solid #d8dddf;
        width: 60%;
        display: flex;
        border-bottom: 1px solid #d8dddf;
        border-left: 1px solid #d8dddf;
        border-top: 1px solid #d8dddf;
        position: relative;

        .positionButtons {
          width: 12%;
          border-right: 1px solid #d8dddf;
          font-size: 20px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 5px;
        }

        .positionName {
          width: 88%;
          display: flex;
          align-items: center;
          padding-left: 12px;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .contentCost {
        width: 10%;
        display: flex;
        align-items: center;
        padding-left: 12px;
        border-bottom: 1px solid #d8dddf;
        border-top: 1px solid #d8dddf;
        border-right: 1px solid #d8dddf;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
