@import '../../../atoms/scss/global';

.advanced-filter {
  position: relative;
  z-index: 3;
  border-radius: 4px;
}

.advance-filters-modal {
  top: 10px !important;
  z-index: 3500;

  .ant-modal-body {
    padding: 0 24px;
  }

  .options-container {
    position: absolute;
    left: 0;
    background: #fff;
    min-width: 600px;

    .toggle-button {
      margin: 0 8px;
    }

    &.not-popup {
      position: initial;
      border: none;
    }

    .options-header {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dark-gray;

      span {
        font-weight: bold;
        font-size: 18px;
      }

      i.fa {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .view-options-section {
      border-radius: 4px;
      padding: 1em !important;
      max-height: 80vh;
      overflow-y: auto;

      * {
        border-color: #999999;
      }

      .ant-select {
        width: 100%;
      }
    }

    .bottom-buttons {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      width: 100%;

      button {
        width: 48%;
        margin: 0;
      }
    }
  }
}

.ant-modal-mask {
  z-index: 1000;
}

.ant-modal-wrap {
  z-index: 1000;
}
