@import '../../atoms/scss/global';

.production-control {
  margin-top: -20px;

  .first-buttons-col {
    button {
      margin-left: 10px;

      &.new-advance {
        margin-right: auto;
        display: flex;
        align-items: center;

        i.fa {
          cursor: pointer;
          font-size: 22px;
          margin-right: 4px;
        }
      }
    }

    .production-select {
      min-width: 180px;

      &.last {
        margin-left: 10px;
      }

      &.with-margin {
        margin-right: auto;
      }

      .ant-select {
        width: 100% !important;
      }
    }

    .edit-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      h4 {
        margin: 0;
      }
      & > div {
        &:nth-child(2) {
          margin-left: auto;
        }
      }
    }
  }

  .view-options {
    margin-left: 0 !important;
  }
}

.switch-cell {
  .rs-table-cell-content {
    height: 27px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .advance-switch {
      &.ant-switch-checked {
        background-color: $light-green;
      }

      .ant-switch-handle {
        background: transparent !important;
      }
    }
  }
}
