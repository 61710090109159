@import '../../atoms/scss/global';

.view-options {
  position: relative;
  margin-left: auto !important;
  z-index: 999 !important;

  .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 18px;
    }

    i.fa {
      font-size: 22px;
      cursor: pointer;
    }
  }

  .options-container {
    position: absolute;
    right: 0;
    border: 2px solid #eee;
    background: #fff;
    min-width: 280px;

    & > div {
      border-bottom: 1px solid #eee;
      padding: 10px;
    }

    .switch-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;

      .switch.ant-switch-checked {
        background-color: $light-green;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: -0.3rem;
      button {
        width: 48%;
        margin: 0;
        min-width: 50%;
      }
    }
  }
}
