.newCalendar {
  .ant-modal {
    top: 10px;
  }

  .formContainer {
    display: flex;
    justify-content: space-between;

    @media (max-width: 950px) {
      flex-direction: column;
    }

    .ant-checkbox-wrapper {
      & > span {
        font-weight: normal !important;
      }
    }

    .form {
      display: flex;

      .ant-form-item {
        min-width: 230px;
        margin-right: 15px;
      }
    }
  }

  .buttons {
    display: flex;

    button {
      min-width: 160px;
      margin-left: 15px;

      @media (max-width: 950px) {
        margin-left: 0px;
        margin-right: 15px;
      }
    }

    .ant-form-item {
      min-width: inherit;
    }
  }

  .yearContainer {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    justify-content: center;
    padding-bottom: 20px;

    & > span {
      padding: 0px 10px;
      cursor: pointer;
    }
  }

  .monthsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .monthName {
      text-align: center;
    }

    .holiday {
      background-color: red;
    }

    @media (max-width: 1100px) {
      justify-content: space-around;
    }

    @media (max-width: 580px) {
      justify-content: center;
    }

    .monthContainer {
      display: flex;
      justify-content: center;

      @media (max-width: 1100px) {
        width: 40%;
      }

      @media (max-width: 580px) {
        width: 80%;
      }
    }
  }

  .ant-picker-calendar {
    width: 250px;
  }

  .ant-picker-content {
    height: 200px !important;
  }

  .ant-picker-calendar-date-value {
    display: none;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected > div {
    background-color: #fff;
    color: inherit;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    content: none;
  }

  .ant-picker-content {
    & > thead {
      & tr {
        & th {
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .ant-picker-cell-disabled::before {
    background: none;
  }
}

.newHoliday {
  .ant-form {
    padding-top: 30px;

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;

      button {
        min-width: 150px;
      }
    }

    .ant-radio-wrapper,
    .ant-radio-wrapper-checked {
      & > span {
        font-weight: normal !important  ;
      }
    }
  }
}
