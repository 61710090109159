.update-job-cost {
  margin-left: auto;
}

.update-job-cost-modal {
  .sync-selector {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .sync-options {
      display: flex;
      flex-direction: column;
      margin-left: 20px !important;
      .ant-radio-wrapper {
        margin-bottom: 4px;
      }
    }
  }
}
