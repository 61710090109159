@import '../../atoms/scss/global';

.cas {
  z-index: 1;
  margin-top: -1.5rem;
  .date-picker-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    .input-width {
      width: 180px;
    }

    .datePiker {
      width: 180px;
    }
  }
  .ViewOptionsRadio-cascd {
    z-index: 5;
  }

  .pnf-date {
    .ant-select {
      min-width: 120px;
    }
  }
}

.orange:not(.editable) {
  background-color: #e2aa41 !important;
  &:hover {
    background-color: #e2aa41 !important;
  }
}

.title-report {
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0 16px 0;
  border-top: 1px solid #d9d9d9;
  margin-top: 16px;

  span {
    font-weight: 500;
  }
}
.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-tabs-tab {
  min-width: 150px !important;
  border: 1px solid $darken-gray !important;
  border-bottom: none !important;
  margin: 0 !important;
  height: 0px !important;
  border-right: 15px solid transparent !important;
  border-bottom: 35px solid $darken-gray !important;
  border-top: 0 !important;
  border-left: 0 !important;
  display: flex !important;
  justify-content: center !important;

  .ant-tabs-tab-btn {
    position: absolute;
    top: 30px;
  }

  &.ant-tabs-tab-active {
    border-color: #f2f2f5;
    border-right-color: transparent;
  }
}

#summary {
  .rs-table-cell-content {
    display: flex;
    justify-content: center !important;
    background: #ffff !important;
    color: #000 !important;
  }
}

#summary-green {
  .rs-table-cell-content {
    background: rgb(164, 199, 136) !important;
    color: #000 !important;
  }
}

#summary-orange {
  .rs-table-cell-content {
    background: rgb(226, 170, 65) !important;
    color: #000 !important;
  }
}

#summary-blue {
  .rs-table-cell-content {
    background: rgb(67, 151, 230) !important;
    color: #000 !important;
  }
}

.align-left-summary {
  div.rs-table-cell-content {
    display: flex !important;
    justify-content: flex-start !important;
    padding-left: 20px !important;
  }
}

.disabled-inputs {
  pointer-events: none;
}

.nowrap-inputs {
  flex-wrap: nowrap !important;
}

.button-modal {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.5rem;
}
