@import '../../atoms/scss/global';

.pnf {
  .pnf-date {
    margin-right: auto;
    .ant-select {
      min-width: 120px;
    }
  }

  .info-icon {
    font-size: 20px !important;
  }

  .rs-table {
    .rs-table-row {
      .ant-select {
        min-width: 120px;
      }
      .type-select .ant-select-selector {
        background-color: $light-yellow;
        border: none;
        padding: 0;
      }
      .plus-cell {
        font-size: 22px;
        cursor: pointer;
        color: $primary-color !important;
      }

      div.rs-table-cell-content {
        padding: 0;
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 12px;
    background: #f2f2f5;

    border: 1px solid #f2f2f5;
    border-top: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-tab {
    min-width: 150px;
    border: 1px solid $darken-gray;
    border-bottom: none;
    margin: 0;
    height: 0px;
    border-right: 15px solid transparent;
    border-bottom: 35px solid $darken-gray;
    border-top: 0;
    border-left: 0;
    display: flex;
    justify-content: center;

    .ant-tabs-tab-btn {
      position: absolute;
      top: 30px;
    }

    &.ant-tabs-tab-active {
      border-color: #f2f2f5;
      border-right-color: transparent;
    }
  }
  .viewoptions-pnf {
    z-index: 5;
  }
}
