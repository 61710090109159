@import '../../../atoms/scss/global';

.viewOptions {
  .options-container {
    .switch-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;

      .switch.ant-switch-checked {
        background-color: $light-green;
      }
    }

    .buttons-options {
      padding-top: 24px;
      display: flex;
      justify-content: space-between;

      button {
        width: 48%;
        margin: 0;
      }
    }
  }
}
