@import '../../../../atoms/scss/global';

.open-button-container {
  background: transparent;
  position: fixed;
  bottom: 15px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    background: $primary-color;
    border-radius: 100px;

    width: 40px;
    height: 40px;

    .file-icon {
      font-size: 22px;
      color: #fff;
    }
  }

  span {
    font-size: 12px;
    font-weight: bold;
    backdrop-filter: blur(2px);
    padding: 2px;
    border-radius: 4px;
  }
}

.resume-box {
  border: 1px solid $dark-gray;
  background: #fff;
  margin-left: auto;
  font-size: 12px;
  position: fixed;
  width: 300px;
  bottom: 10px;
  right: 10px;
  border-radius: 4px;

  i.fa {
    font-size: 18px;
    cursor: pointer;
    top: 9px !important;
  }

  .resume-title {
    background: $darken-gray;
    padding: 8px;
    border-bottom: 1px solid $dark-gray;
    text-align: center;
    font-weight: bold;
    position: relative;

    i.fa {
      position: absolute;
      top: 12px;
      right: 10px;
    }
  }

  .ant-input-number {
    min-width: 120px;
    input.ant-input-number-input {
      background: $light-yellow;
      font-size: 12px;
    }
  }

  .resume-content {
    padding: 11px 20px;

    & > div {
      display: flex;
      padding: 4px 0;
      & > div {
        &:nth-child(1) {
          width: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        &:nth-child(2) {
          width: 50%;
        }
      }
    }

    .discount {
      color: red;
    }

    .with-border {
      border-bottom: 1px solid $dark-gray;
    }

    &.saving {
      opacity: 0.5;
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;

  button {
    width: calc(50% - 10px);
  }
}
