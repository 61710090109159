@import '../../../atoms/scss/global';

.positionContainer {
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    button {
      min-width: 150px;
    }
  }

  .ant-input-number {
    width: 100%;
  }
}
