.spinerContainer {
  position: absolute;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 18px;

  .spiner {
    display: flex;
    padding-right: 10px;
  }
}

.tableContainer {
  overflow: auto;
  color: #000;
  position: relative;

  .headerContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    background-color: #fff;
    z-index: 1;

    .headerText {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      user-select: none;
      padding-top: 5px;
      border-bottom: 1px solid #f2f2f5;
      border-left: 1px solid #f2f2f5;
      display: flex;
      align-items: center;
    }
  }

  .columnContainer {
    position: relative;

    .fixedColumn {
      position: absolute;
      left: 0px;
      top: 0px;
      background-color: #fff;
      z-index: 1;
      border-right: 1px solid #f2f2f5;

      .headerInFixedColumn {
        background-color: #fff;
        position: absolute;
        display: flex;
        align-items: flex-end;
        padding-left: 5px;
        padding-bottom: 10px;
        border-top: 1px solid #f2f2f5;
        border-left: 1px solid #f2f2f5;
        border-right: 1px solid #f2f2f5;
      }
    }

    .column {
      display: flex;

      .cellContainer {
        border-top: 1px solid #f2f2f5;
        display: flex;
        align-items: center;
      }

      .cellInFixedColumn {
        padding-left: 5px;
        border-left: 1px solid #f2f2f5;
      }

      .cellInNormalColumn {
        justify-content: center;
        border-right: 1px solid #f2f2f5;
        user-select: none;
      }
    }
  }
}
