@import '../../atoms/scss/global';

.advance-income {
  min-height: 100vh;
  background-color: #fafafa;
  padding: 20px 10px 0 10px;
  .view-options-wrapper {
    z-index: 1001;
  }
  .second-buttons-col {
    z-index: 1000;

    .fa-filter {
      position: relative;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
      &::after {
        content: 'x';
        position: absolute;
        top: -1px;
        right: -2px;
        font-size: 13px;
        font-weight: bold;
        font-family: sans-serif;
        color: red;
      }

      &.disabled {
        pointer-events: none;
        color: gray;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  .first-buttons-col {
    padding: 0 15px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .select-date-container {
      position: relative;
    }
  }
  .advance-income-col {
    border: 1px solid $darken-gray;
    min-height: calc(100vh - 200px);
    overflow-x: auto;

    &.first {
      border-radius: 8px 0 0 8px;
    }

    &.second {
      border-radius: 0 8px 8px 0;
      .select {
        width: calc(20% - 10px);
        margin-right: 10px;
        .ant-select {
          width: 100% !important;
        }
      }
    }
  }

  .search-inputs {
    input,
    .ant-select {
      width: 150px;
    }
  }
  .top-select {
    margin-right: 10px;
    .ant-select {
      min-width: 250px;
    }

    &.long {
      .ant-select {
        min-width: 220px;
      }
    }
  }

  .date-select {
    .ant-select {
      min-width: 120px;
    }
  }

  .checkboxes {
    & > span {
      margin-right: 8px;
      margin-left: 0 !important;
    }
  }

  .cap-level {
    color: $green;
    font-weight: bold;
  }

  .spin {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 30px;
  }
}
.id-filter {
  width: 100px;
}
.name-filter {
  width: 200px;
}

.advance-income-col-loading {
  svg {
    font-size: 24px;
  }
}
