@import '../../atoms/scss/global';

.tree-filter {
  position: relative;
  z-index: 3;
  border-radius: 4px;

  .options-container {
    position: absolute;
    left: 0;
    border: 1px solid $dark-gray;
    background: #fff;
    min-width: 340px;
    border-radius: 4px;

    .options-header {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dark-gray;

      span {
        font-weight: bold;
        font-size: 18px;
      }

      i.fa {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 8px;

      button {
        width: 48%;
        margin: 0;
      }
    }
  }
}
