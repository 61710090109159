.table-wrapper {
  overflow-x: auto;

  table {
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .fa {
    cursor: pointer;
    font-size: 22px;
    margin-right: 4px;
  }

  .table-head-tools {
    display: flex;
    margin-bottom: 1em;
    justify-content: flex-end;
    margin-top: 1em;

    .left-tools {
      margin-right: auto;
      display: flex;
      font-size: 16px;
    }

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .search {
      max-width: 250px;
      margin-left: 5px;
    }
  }

  td.ant-table-cell {
    font-size: 12px !important;
  }
}
