@import '../../../atoms/scss/global';

.paymentStatusHeader {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid #000;
  background: $purple-light;
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 15px;

  .filters {
    display: flex;
  }

  .marginRight {
    margin-right: 20px;
  }

  .orangeButton {
    background-color: #f87c1d !important;
    color: #fff;
    padding: 5px 30px;
    border: none;
    width: 100%;

    > span {
      color: #fff;
    }
  }

  .disabledButton {
    opacity: 0.5;
    pointer-events: none;
  }
}
