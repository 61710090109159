@import '../../atoms/scss/global';

.cas {
  .date-picker-wrapper {
    margin-right: auto;
  }

  .pnf-date {
    .ant-select {
      min-width: 120px;
    }
  }
  .viewoptionsRadio-cas {
    z-index: 5;
  }
}
