@import '../../../atoms/scss/global';

.add-option {
  button {
    margin-right: 10px;
  }
}

.icons-list-wrapper {
  padding: 0 20px;
  margin-top: -20px;
  span {
    font-size: 12px;
    font-weight: normal;
    margin-left: 6px;
  }

  .icons-list {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
    margin-top: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .icon-wrapper {
    cursor: pointer;
    min-width: 30px;
    width: 30px;
    min-height: 30px;
    height: 30px;
    border: 1px solid;
    margin: 6px;
    padding: 6px;

    &.selected {
      background-color: $primary-color;
      i.fa {
        color: #fff;
      }
    }

    i.fa {
      font-size: 18px;
    }
  }
}
